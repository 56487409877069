"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.destroyMailkey = exports.createMailkey = exports.autocompleteUsers = exports.autocompleteOrganizations = exports.getUsers = exports.getOrganizationsByIds = exports.getOrganizations = exports.getOrganization = exports.changeActiveOrg = exports.getUser = exports.getMe = void 0;
var session_js_1 = require("./session.js");
var common_js_1 = require("./common.js");
var url_js_1 = require("../util/url.js");
var paginate_js_1 = require("../util/paginate.js");
var base_js_1 = require("./base.js");
function getMe(expand) {
    if (expand === void 0) { expand = common_js_1.DEFAULT_EXPAND; }
    return __awaiter(this, void 0, void 0, function () {
        var _a, status, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    // Check that the user is logged in via cookies
                    if (session_js_1.cookiesEnabled && !(0, session_js_1.getCsrfToken)())
                        return [2 /*return*/, null];
                    return [4 /*yield*/, session_js_1.default.get((0, url_js_1.queryBuilder)((0, base_js_1.apiUrl)("users/me/"), { expand: expand }))];
                case 1:
                    _a = _b.sent(), status = _a.status, data = _a.data;
                    if (status !== 200)
                        return [2 /*return*/, null];
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.getMe = getMe;
function getUser(id, expand) {
    if (expand === void 0) { expand = common_js_1.DEFAULT_EXPAND; }
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, session_js_1.default.get((0, url_js_1.queryBuilder)((0, base_js_1.apiUrl)("users/".concat(id, "/")), { expand: expand }))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.getUser = getUser;
function changeActiveOrg(orgId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, session_js_1.default.patch((0, base_js_1.apiUrl)("users/me/"), { organization: orgId })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.changeActiveOrg = changeActiveOrg;
function getOrganization(id, expand) {
    if (expand === void 0) { expand = common_js_1.ORG_EXPAND; }
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, session_js_1.default.get((0, url_js_1.queryBuilder)((0, base_js_1.apiUrl)("organizations/".concat(id, "/")), { expand: expand }))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.getOrganization = getOrganization;
function getOrganizations(individual, expand) {
    if (individual === void 0) { individual = null; }
    if (expand === void 0) { expand = common_js_1.ORG_EXPAND; }
    return __awaiter(this, void 0, void 0, function () {
        var orgs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, paginate_js_1.grabAllPages)((0, url_js_1.queryBuilder)((0, base_js_1.apiUrl)("organizations/"), { individual: individual, expand: expand }))];
                case 1:
                    orgs = _a.sent();
                    return [2 /*return*/, orgs];
            }
        });
    });
}
exports.getOrganizations = getOrganizations;
function getOrganizationsByIds(ids, expand) {
    if (expand === void 0) { expand = common_js_1.ORG_EXPAND; }
    return __awaiter(this, void 0, void 0, function () {
        var orgs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, paginate_js_1.grabAllPages)((0, url_js_1.queryBuilder)((0, base_js_1.apiUrl)("organizations/"), { id__in: ids, expand: expand }))];
                case 1:
                    orgs = _a.sent();
                    return [2 /*return*/, orgs];
            }
        });
    });
}
exports.getOrganizationsByIds = getOrganizationsByIds;
function getUsers(_a, expand) {
    var projectIds = _a.projectIds, orgIds = _a.orgIds;
    if (expand === void 0) { expand = common_js_1.USER_EXPAND; }
    return __awaiter(this, void 0, void 0, function () {
        var query, users;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (projectIds != null && orgIds != null) {
                        throw new Error("Only specify one of project or org ids");
                    }
                    query = { expand: expand };
                    if (projectIds != null) {
                        query["project"] = projectIds.map(String).join(",");
                    }
                    if (orgIds != null) {
                        query["organization"] = orgIds.map(String).join(",");
                    }
                    return [4 /*yield*/, (0, paginate_js_1.grabAllPages)((0, url_js_1.queryBuilder)((0, base_js_1.apiUrl)("users/"), query))];
                case 1:
                    users = _b.sent();
                    return [2 /*return*/, users];
            }
        });
    });
}
exports.getUsers = getUsers;
function autocompleteOrganizations(prefix, individual) {
    if (prefix === void 0) { prefix = ""; }
    if (individual === void 0) { individual = false; }
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, session_js_1.default.get((0, url_js_1.queryBuilder)((0, base_js_1.apiUrl)("organizations/"), {
                        name__istartswith: prefix,
                        individual: individual,
                        version: "2.0",
                    }))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.results];
            }
        });
    });
}
exports.autocompleteOrganizations = autocompleteOrganizations;
function autocompleteUsers(prefix, orgIds) {
    if (prefix === void 0) { prefix = ""; }
    if (orgIds === void 0) { orgIds = null; }
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, session_js_1.default.get((0, url_js_1.queryBuilder)((0, base_js_1.apiUrl)("users/"), {
                        name__istartswith: prefix,
                        organization: orgIds == null ? null : orgIds.map(function (x) { return "".concat(x); }).join(","),
                        version: "2.0",
                    }))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.results];
            }
        });
    });
}
exports.autocompleteUsers = autocompleteUsers;
function createMailkey() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, session_js_1.default.post((0, base_js_1.apiUrl)("users/mailkey/"))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.mailkey];
            }
        });
    });
}
exports.createMailkey = createMailkey;
function destroyMailkey() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, session_js_1.default.delete((0, base_js_1.apiUrl)("users/mailkey/"))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.destroyMailkey = destroyMailkey;
